import { Injectable } from '@angular/core';
import { dockerEnvironment } from '../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { RegisterInstitutionCommand } from '../../core/models/commads';
import { AnyTxtRecord } from 'node:dns';

@Injectable({
  providedIn: 'root'
})
export class EntidadeSocialService {

  private apiBaseUrl = dockerEnvironment.apiUrl

  private apiBaseUrlIR = dockerEnvironment.apiUrl + `/v1/institution`

  constructor(private http: HttpClient) { }

  create(institution: RegisterInstitutionCommand) {
    return this.http.post<any>(`${this.apiBaseUrl}/v1/auth/institution/register`, institution)
  }

  list(char: string) {
    return this.http.get<any>(`${this.apiBaseUrl}/v1/institution?perPage=99999`)
  }

  listInstitutionRequest(page: number = 0, perPage: number = 10,  status?: string, startDate?: string, endDate?: string, cnpj?: string, tipo?: string) {
    let url = `${this.apiBaseUrlIR}?page=${page}&per_page=${perPage}`;

    if (startDate) {
      url += `&start_date=${startDate}`;
    }

    if (endDate) {
      url += `&end_date=${endDate}`;
    }

    if (cnpj) {
      url += `&cnpj=${cnpj}`;
    }

    if (status) {
      url += `&status=${status}`;
    }

    if (tipo) {
      url += `&type=${tipo}`;
    }

    return this.http.get<any>(url);
  }

  listInstitutionCadastradas(page: number = 0, perPage: number = 10, nome?: string, municipio?: string) {
    let url = `${dockerEnvironment.apiUrl}/v1/backoffice/list-institutions?page=${page}&per_page=${perPage}`;

    if (nome) {
      url += `&nome=${nome}`;
    }

    if (municipio) {
      url += `&municipio=${municipio}`;
    }

    return this.http.get<any>(url);
  } 

  codeSolicit(code: string) {
    return this.http.get<any>(`${this.apiBaseUrl}/v1/institution-request/${code}/follow-up`)
  }

  changeStatus(body: any) {
    return this.http.post<any>(`${this.apiBaseUrl}/v1/institution-request/change-status-type`, body)

  }

  getByIdCompose(id: string) {
    return this.http.get<any>(`${this.apiBaseUrl}/v1/institution/${id}`)
  }

  homol(id: string, entidade?: any) {
    return this.http.put<any>(`${this.apiBaseUrl}/v1/institution/${id}`, entidade)
  }

  firstAccess(id: string) {
    return this.http.get<any>(`${this.apiBaseUrl}/v1/institution/firstAccess/${id}`)
  }

  recordPassword(obj: any) {
    return this.http.put<any>(`${this.apiBaseUrl}/v1/institution/recordPassword`, obj)
  }

  login(obj: any) {
    return this.http.post<any>(`${this.apiBaseUrl}/v1/auth/institution/authenticate`, obj)
  }

  getInstitution(id: any) {
    return this.http.get<any>(`${this.apiBaseUrl}/v1/institution/${id}`)
  }

  getRequestInstitution(id: any) {
    return this.http.get<any>(`${this.apiBaseUrl}/v1/institution-request/${id}`)
  }

  listByApportionment(
    page?: number,
    perPage?: number,
    name?: string,
    apportionment?: any | undefined | null,
    date?: string | null
  ) {

    let url = `${this.apiBaseUrl}/v1/institution/list-by-apportionment?page=${page}&perPage=${perPage}`;

    // Adiciona os parâmetros à URL, se eles foram fornecidos
    if (name) {
      url += `&name=${encodeURIComponent(name)}`;
    }
    if (apportionment && apportionment!= "undefined" ) {
      url += `&apportionment=${encodeURIComponent(apportionment)}`;
    }
    if (date) {
      url += `&competence=${encodeURIComponent(date)}`;
    }

    // Realize a chamada HTTP
    return this.http.get<any>(url);
  }

  downloadArchive(request: any) {
    return this.http.get<any>(this.generateQueryParams(request), { responseType: 'blob' as 'json' }) 
  }

  generateQueryParams(request: any) {
     
    
    const url = new URL(`${this.apiBaseUrl}/v1/files-service/download`);
    url.searchParams.append('cpfCnpj', request.cpfCnpj);
    request.fileHashes.forEach((hash: string) => url.searchParams.append('fileHashes', hash));
    return url.toString();
  }
}




